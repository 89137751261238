import classNames from 'classnames'
import SvgIcon from '../SvgIcon'
import { useRouter } from 'next/router'
import { useAnalytics } from 'src/common/analytics'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'
import { useCallback, useEffect, useState } from 'react'
import { getCookie, setCookie } from 'cookies-next'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { APP_BASE_PATH } from 'src/common/constants'
import Link from 'next/link'

const CONTENTFUL_ENTRY_ID = '2ITbD2j9Uz8uG2nKpPoggv'
const CONTENTFUL_ENTRY_VERSION = '2'
const CONSENT_BANNER_COOKIE_KEY = 'tracking_consent_banner'
const CONSENT_BANNER_DISCLOSURE =
  'We use tracking technologies such as pixels and session replay tools to collect your information and interactions with our websites. By continuing to use our sites or closing this dialog, you acknowledge and agree to our use of tracking technologies. Please review our /privacy policy/ for more information.'

const PAGES_WITH_BOTTOM_CONSENT_BANNER = [
  '/',
  '/how-it-works',
  '/who-we-help',
  '/why-were-better',
  '/testimonials',
  '/faq',
  '/about',
  '/contact',
  '/media-assets',
  '/press-releases',
]
const CloseButton = ({ onClick }) => {
  return (
    <button className="h-8 w-8" onClick={() => onClick?.()}>
      <SvgIcon name="closeModal" />
    </button>
  )
}

const ConsentText = () => {
  return (
    <p className="text-xs font-normal lg:text-sm">
      {CONSENT_BANNER_DISCLOSURE.split('/').map((item) => {
        if (item.includes('privacy policy'))
          return (
            <Link href="/freedom-debt-relief-california-privacy-disclosures">
              {' '}
              privacy policy{' '}
            </Link>
          )
        else return item
      })}
    </p>
  )
}

const ConsentButton = ({ onClick }) => {
  return (
    <button
      className="rounded-lg bg-blue-600 py-2 px-12 text-sm font-bold text-white lg:py-3 lg:text-lg"
      onClick={() => onClick?.()}
    >
      Ok
    </button>
  )
}

export const ConsentBanner = () => {
  const [showConsent, setShowConsent] = useState(false)
  const { getExperimentVariation } = useFeatureFlags()
  const consentBannerFlag = getExperimentVariation(
    experimentSectionByVariation[featureToggles.CONSENT_BANNER]
  )
  const { pathname } = useRouter()
  const { track } = useAnalytics()
  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: () => {
      track(
        {
          additional_info: {
            disclosure_copy: CONSENT_BANNER_DISCLOSURE,
          },
          event_type: 'view',
          nav_link_section: 'TCB banner',
        },
        {},
        '_views/policy'
      )
    },
    freezeOnceVisible: true,
  })

  const generateCookie = () => {
    const visitId = getCookie('eh_visit_id')
    if (!visitId) return
    const parsedUrl = new URL(APP_BASE_PATH())

    setCookie(
      CONSENT_BANNER_COOKIE_KEY,
      JSON.stringify({
        visitId,
        contentfulId: CONTENTFUL_ENTRY_ID,
        contentfulVersion: CONTENTFUL_ENTRY_VERSION,
      }),
      { maxAge: 365 * 10 * 24 * 60 * 60, domain: parsedUrl.hostname }
    )
  }

  const handleAgree = useCallback(() => {
    const data = {
      list_name: 'Tracking consent click event',
      nav_link_section: 'TCB banner',
      click_text: 'Ok',
      event_action: 'button_click',
    }

    generateCookie()
    track(data, {}, 'button_click')
    setShowConsent(false)
  }, [track])

  const handleClose = useCallback(() => {
    const data = {
      list_name: 'Close consent click event',
      nav_link_section: 'TCB banner',
      event_action: 'button_click',
    }

    generateCookie()
    track(data, {}, 'button_click')
    setShowConsent(false)
  }, [track])

  useEffect(() => {
    const cookieValue = getCookie(CONSENT_BANNER_COOKIE_KEY)

    if (!cookieValue) setShowConsent(true)
    else setShowConsent(false)
  }, [setShowConsent])

  const showConsentBanner = consentBannerFlag === 'test' && showConsent
  const isBrandPage = PAGES_WITH_BOTTOM_CONSENT_BANNER.includes(pathname)

  if (!showConsentBanner) return null

  return (
    <div
      ref={ref}
      className={classNames(
        'fixed left-0 z-80 w-full bg-white px-5 py-3 lg:px-16 lg:py-6',
        isBrandPage
          ? 'bottom-0'
          : 'top-0 bottom-auto pt-20 lg:bottom-0 lg:top-auto lg:pt-0'
      )}
    >
      {/* Desktop */}
      <div className="hidden items-center justify-between lg:flex">
        <ConsentText />
        <div className="ml-4">
          <ConsentButton onClick={handleAgree} />
        </div>
        <div className="ml-18 flex items-center justify-end">
          <CloseButton onClick={handleClose} />
        </div>
      </div>
      {/* Mobile */}
      <div className="flex flex-col items-center gap-3 lg:hidden">
        <div className="flex">
          <ConsentText />
          <div className="ml-2">
            <CloseButton onClick={handleClose} />
          </div>
        </div>
        <ConsentButton onClick={handleAgree} />
      </div>
    </div>
  )
}
